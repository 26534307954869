// AddLevelModal.tsx
import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input, Spinner } from 'reactstrap';
import { addLevel, getCourses } from '../../../../Api/lms/apiUtils';
import { toast } from 'react-toastify';

interface Level {
  level_id: number;
  level_name: string;
}

interface Course {
  course_id: number;
  course_name: string;
  levels: Level[];
}

interface AddLevelModalProps {
  courseName: string;
  courseId: number;
  existingLevels: Level[];
  allLevels: { id: number; name: string }[];
  isOpen: boolean;
  toggle: () => void;
}

const AddLevelModal: React.FC<AddLevelModalProps> = ({
  courseName,
  courseId,
  existingLevels,
  allLevels,
  isOpen,
  toggle,
}) => {
  // State to store selected levels, loader, and course levels
  const [selectedLevels, setSelectedLevels] = useState(new Set(existingLevels.map((level) => level.level_name)));
  const [isLoading, setIsLoading] = useState(false);
  const [courseLevels, setCourseLevels] = useState<Level[]>(existingLevels); // State to hold current levels

  useEffect(() => {
    if (isOpen) {
      fetchCourseLevels(); // Fetch current course levels when modal opens
    }
  }, [isOpen]);

  const handleCheckboxChange = (levelName: string) => {
    const newSelection = new Set(selectedLevels);
    newSelection.has(levelName) ? newSelection.delete(levelName) : newSelection.add(levelName);
    setSelectedLevels(newSelection);
  };

  const fetchCourseLevels = async () => {
    setIsLoading(true);
    try {
      const response = await getCourses();
      const course = response.courses.find((course: Course) => course.course_id === courseId);
      if (course) {
        setCourseLevels(course.levels); // Set fetched course levels
      }
    } catch (error) {
      console.error('Error fetching courses:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveLevel = async () => {
    const newLevels = Array.from(selectedLevels).filter(
      (levelName) => !existingLevels.some((level) => level.level_name === levelName)
    );

    setIsLoading(true); // Show loading spinner while saving
    try {
      for (const levelName of newLevels) {
        const reqParam = {
          courseId: courseId,
          levelName: levelName,
          description: "", // Optional description can be customized
        };
        const response = await addLevel(reqParam);
        if (response.status === 201) {
          toast.success(`Level "${levelName}" added successfully`);
          fetchCourseLevels();
          setCourseLevels((prevLevels) => [
            ...prevLevels,
            { level_id: response.data.id, level_name: levelName },
          ]);
        } else {
          console.error('Failed to add level:', response.data.message);
        }
      }
      toggle(); // Close modal after saving levels
    } catch (error) {
      console.error('Error adding levels:', error);
      toast.error('Error adding levels');
    } finally {
      setIsLoading(false); // Hide loading spinner
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Add Level to {courseName}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="courseName">Course Name</Label>
          <Input type="text" id="courseName" value={courseName} disabled />
        </FormGroup>
        <FormGroup>

          {allLevels.map((level) => (
            <Label key={level.id} check>
              <Input
                type="checkbox"
                className="levelChk"
                checked={selectedLevels.has(level.name)}
                onChange={() => handleCheckboxChange(level.name)}
                disabled={courseLevels.some((existing) => existing.level_name === level.name)} // Disable if already exists
              />
              {level.name}
            </Label>
          ))}
        </FormGroup>
    
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSaveLevel} disabled={isLoading}>
          {isLoading ? 'Saving...' : 'Add Levels'}
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddLevelModal;
