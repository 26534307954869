import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Spinner,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Progress
} from 'reactstrap';
import { Video, Music, FileText, Plus } from 'react-feather';
import { searchResources } from '../../../../Api/lms/apiUtils';
import {_addResource} from '../../../../Api/lms/utility';
import PreviewComponent from './PreviewComponent';
import { toast } from "react-toastify";
import { Trash } from 'react-feather';


interface Resource {
  resource_id: number;
  resource_name: string;
  type: string;
  description: string;
  url: string;
  created_at: string;
  updated_at: string;
  display_name: string;
  fileUrl: string;
}

function ResourcesList() {
  const [resources, setResources] = useState<Resource[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [previewResource, setPreviewResource] = useState<Resource | null>(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newFile, setNewFile] = useState<File | null>(null);
  const [displayName, setDisplayName] = useState<string>('');
  const [isSaving, setIsSaving] = useState<boolean>(false); // New state to prevent multiple saves

  const observerRef = useRef<IntersectionObserver | null>(null);
  const lastResourceRef = useRef<HTMLDivElement | null>(null);

  const fetchResources = async (pageNumber: number) => {
    setIsLoading(true);
    try {
      const response = await searchResources(searchQuery, pageNumber, 10);
      const newResources = response.results || [];

      if (newResources.length === 0) {
        setHasMore(false);
      } else {
        setResources((prevResources) => [...prevResources, ...newResources]);
      }
    } catch (error) {
      console.error('Error fetching resources:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setResources([]);
    setPage(1);
    setHasMore(true);
    fetchResources(1);
  }, [searchQuery]);

  useEffect(() => {
    if (page > 1) {
      fetchResources(page);
    }
  }, [page]);

  const handleScroll = () => {
    if (
      lastResourceRef.current &&
      lastResourceRef.current.getBoundingClientRect().bottom <= window.innerHeight + 100 &&
      !isLoading &&
      hasMore
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasMore, isLoading]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handlePreviewToggle = (resource: Resource | null) => {
    setPreviewResource(resource);
    setIsPreviewOpen(!isPreviewOpen);
  };

  const handleSave = async () => {
    if (newFile && !isSaving) {  // Prevent save if it's already saving
      setIsSaving(true);  // Set the saving state to true
      setIsLoading(true);
      try {
        let resourceRes = await _addResource([newFile], displayName);
        if (resourceRes) {
          toast.success('Resource added successfully');
          setDisplayName('');
          setResources([]);
          setPage(1);
          fetchResources(1);
        }
      } catch (error) {
        console.error('Error adding resource:', error);
        toast.error('Error adding resource');
      } finally {
        setIsSaving(false); // Reset the saving state after the process
        setIsLoading(false);
        setIsModalOpen(false);
      }
    }
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <div>
      <FormGroup style={{ marginTop: '10px' }}>
        <Input
          type="text"
          placeholder="Search resources..."
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </FormGroup>

      <Button color="primary" onClick={toggleModal}>
        <Plus size={18} /> Add Resource
      </Button>

      {isLoading && resources.length === 0 ? (
        <Row className="user-cards-items user-card-wrapper" style={{ marginTop: '15px' }}>
          <Col xs="12" className="text-center">
            <Spinner color="primary" />
            <p>Loading resources...</p>
          </Col>
        </Row>
      ) : (
        <Row className="user-cards-items user-card-wrapper">
          {resources.length > 0 ? (
            resources.map((resource, index) => (
              <Col
                key={resource.resource_id}
                xl="4"
                sm="6"
                xxl="3"
                className="col-ed-4 box-col-4"
              >
                <div ref={index === resources.length - 1 ? lastResourceRef : null}>
                 <Card
                    style={{ maxHeight: '80vh', overflowY: 'auto', marginTop: '10px', position: 'relative' }}
                    onClick={() => handlePreviewToggle(resource)}
                 >
                  <CardBody style={{ position: 'relative' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <div
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          marginLeft: '-14px',
                        }}
                      >
                        {resource.type === 'video' && <Video color="grey" />}
                        {resource.type === 'audio' && <Music color="grey" />}
                        {resource.type === 'pdf' && <FileText color="grey" />}
                        {resource.type === 'mp3'  && <Music color="grey" />}
                        {resource.type === 'mp4'  && <Video color="grey" />}
                        <h6 style={{ marginLeft: '10px' }}>
                          {resource.display_name ? resource.display_name.split('.')[0] : resource.resource_name}
                        </h6>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                </div>
              </Col>
            ))
          ) : (
            <Col xs="12" className="text-center">
              <p>No resources found</p>
            </Col>
          )}
        </Row>
      )}

      {isLoading && resources.length > 0 && (
        <Row className="user-cards-items user-card-wrapper" style={{ marginTop: '15px' }}>
          <Col xs="12" className="text-center">
            <Spinner color="primary" />
          </Col>
        </Row>
      )}

<Modal isOpen={isModalOpen} toggle={toggleModal}>
  <ModalHeader toggle={toggleModal}>Add Resource</ModalHeader>
  <ModalBody>
    <FormGroup>
      <Input
        type="file"
        accept=".mp4,.mp3,.pdf,.doc,.docx,.avi,.mov,.wmv,.flv,.mkv" // Specify allowed file types here
        onChange={(e) => {
          if (e.target.files && e.target.files[0]) {
            setNewFile(e.target.files[0]);
            setDisplayName(e.target.files[0].name.split('.')[0]);
          }
        }}
      />
    </FormGroup>
    <FormGroup>
      <Input
        type="text"
        value={displayName}
        onChange={(e) => setDisplayName(e.target.value)}
        placeholder="Display Name"
      />
    </FormGroup>
  </ModalBody>
  <ModalFooter>
    <Button color="primary" onClick={handleSave} disabled={isSaving}>
      {isSaving ? <Spinner size="sm" /> : 'Save'}
    </Button>{' '}
    <Button color="secondary" onClick={toggleModal}>
      Cancel
    </Button>
  </ModalFooter>
</Modal>


      {previewResource && (
        <PreviewComponent
          resource={previewResource}
          isOpen={isPreviewOpen}
          onClose={() => handlePreviewToggle(null)}
        />
      )}
    </div>
  );
}

export default ResourcesList;
