import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button } from 'reactstrap';
import { getSongURL } from '../../../../Api/lms/apiUtils'; // Import your API utility

interface Song {
  song_id: number;
  song_name: string;
  karaoke_url: string;
  song_url: string;
  lyrics_id: number;
  annotation_id: number;
  kids_friendly: boolean;
}

interface SongSearchComponentProps {
  setSongs: any;
}

const SongSearchComponent: React.FC<SongSearchComponentProps> = ({ setSongs }) => {
  const [query, setQuery] = useState<string>('');
  const [filteredSongs, setFilteredSongs] = useState<Song[]>([]);
  const [displayedSongs, setDisplayedSongs] = useState<Song[]>([]);
  const [visibleItems, setVisibleItems] = useState<number>(10); // Load 10 items initially
  const [selectedSongs, setSelectedSongs] = useState<Set<number>>(new Set());
  const [isLoading, setIsLoading] = useState<boolean>(false); // Loading state
  const [page, setPage] = useState<number>(1); // Keep track of the current page
  const [hasMore, setHasMore] = useState<boolean>(true); // To check if more songs are available
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);

  const lastSongRef = useCallback(
    (node: HTMLDivElement) => {
      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore && !isLoading) {
          loadMoreSongs(); // Load more songs when user reaches the bottom
        }
      });

      if (node) observerRef.current.observe(node);
    },
    [hasMore, isLoading]
  );

  const loadMoreSongs = async () => {
    setIsLoading(true);
    try {
      const nextPage = page + 1;
      const response = await getSongURL(query, nextPage, 10); // Fetch next 10 songs
      const newSongs = response.results || [];

      if (newSongs.length > 0) {
        setFilteredSongs(prevSongs => [...prevSongs, ...newSongs]); // Append new songs
        setDisplayedSongs(prevSongs => [...prevSongs, ...newSongs]);
        setPage(nextPage); // Increment page number
      } else {
        setHasMore(false); // No more songs to load
      }
    } catch (error) {
      console.error('Error fetching more songs:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch songs based on the search query
  const fetchSongs = async (searchQuery: string = '') => {
    setIsLoading(true);
    setPage(1); // Reset the page number when fetching new data
    try {
      const response = await getSongURL(searchQuery, 1, 10); // Load first 10 songs
      const newSongs = response.results || [];
      setFilteredSongs(newSongs);
      setDisplayedSongs(newSongs);
      setHasMore(newSongs.length === 10); // Check if there are more songs
    } catch (error) {
      console.error('Error fetching songs:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch initial songs when component mounts (no query)
  useEffect(() => {
    fetchSongs(); // Fetch initial songs with no query
  }, []);

  // Debounce search input changes
  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      if (query) {
        fetchSongs(query); // Fetch songs based on search query
      } else {
        fetchSongs(); // Fetch initial songs when query is cleared
      }
    }, 1000); // 1 second debounce

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current); // Cleanup timeout on component unmount or query change
      }
    };
  }, [query]);

  const toggleSongSelection = (song_id: number) => {
    setSelectedSongs(prevSelected => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(song_id)) {
        newSelected.delete(song_id);
      } else {
        newSelected.add(song_id);
      }
      return newSelected;
    });
  };

  const addSelectedSongs = () => {
    const selected = filteredSongs.filter(song => selectedSongs.has(song.song_id));
    setSongs(selected); 
    setSelectedSongs(new Set()); 
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Search songs..."
        value={query}
        onChange={(e) => {
          setQuery(e.target.value);
          setPage(1); // Reset page when a new query is typed
        }}
        style={{ width: '100%', padding: '10px', marginBottom: '10px' }}
      />

      {/* Loading Spinner */}
      {isLoading && <p>Loading...</p>}

      {/* Search Results */}
      <div style={{ height: '200px', overflowY: 'auto', border: '1px solid #ccc', marginBottom: '10px' }}>
        {displayedSongs.map((song, index) => {
          const isSelected = selectedSongs.has(song.song_id);

          if (index === displayedSongs.length - 1) {
            return (
              <div
                ref={lastSongRef} // Attach observer to the last song
                key={song.song_id}
                style={{ padding: '10px', borderBottom: '1px solid #ddd' }}
              >
                <input
                  type="checkbox"
                  checked={isSelected}
                  onChange={() => toggleSongSelection(song.song_id)}
                  style={{ marginRight: '10px' }}
                />
                <h3>{song.song_name}</h3>
              </div>
            );
          } else {
            return (
              <div key={song.song_id} style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                <input
                  type="checkbox"
                  checked={isSelected}
                  onChange={() => toggleSongSelection(song.song_id)}
                  style={{ marginRight: '10px' }}
                />
                <h3>{song.song_name}</h3>
              </div>
            );
          }
        })}
        {!query && !isLoading && filteredSongs.length === 0 && (
          <p>No songs found. Start typing to search for songs...</p>
        )}
      </div>

      {/* Add Songs Button */}
      <Button color="primary" onClick={addSelectedSongs} style={{ marginBottom: '10px' }}>
        Add Selected Songs
      </Button>
    </div>
  );
};

export default SongSearchComponent;
