import React from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

interface PreviewModalProps {
  isOpen: boolean;
  toggle: () => void;
  fileUrl: string;
  title: string;
}

const PreviewModal: React.FC<PreviewModalProps> = ({ isOpen, toggle, fileUrl, title }) => {
  const isPdf = fileUrl.endsWith('.pdf');

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        {fileUrl ? (
          isPdf ? (
            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
              <Viewer fileUrl={fileUrl} />
            </Worker>
          ) : (
            <img src={fileUrl} alt={title} style={{ width: '100%', height: 'auto' }} />
          )
        ) : (
          <p>No file to preview</p>
        )}
      </ModalBody>
      <div style={{ padding: '1rem', display: 'flex', justifyContent: 'flex-end' }}>
        <Button color="secondary" onClick={toggle}>Close</Button>
      </div>
    </Modal>
  );
};

export default PreviewModal;
