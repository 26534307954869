import React, { useState } from 'react';
import { Button, FormGroup, Label, Input, ListGroup, ListGroupItem, Card, CardHeader, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { addAssignment } from '../../../../Api/lms/apiUtils';
import { Trash } from 'react-feather';
import { toast } from "react-toastify";
import ReactQuill from 'react-quill';

interface Resource {
  resourceName: string;
  type: string;
  description: string;
  url: string;
}

interface Assignment {
  name: string;
  description: string;
  resources: Resource[];
}

interface AddAssignmentCardProps {
  topicId: number;
  onSave: (assignment: Assignment) => void;
  onCancel: () => void;
  existingResources: Resource[];
  topic: any;
  deleteAssignment: any;
  openDeleteModal: any;
}

const AddAssignmentCard: React.FC<AddAssignmentCardProps> = ({ topicId, onSave, onCancel, existingResources, topic, openDeleteModal }) => {
  const [assignmentName, setAssignmentName] = useState<string>('');
  const [assignmentInstruction, setAssignmentInstruction] = useState<any>('');
  const [selectedResources, setSelectedResources] = useState<Resource[]>([]);
  const [submittedAssignments, setSubmittedAssignments] = useState<Assignment[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [editorContent, setEditorContent] = useState<string>('');

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleAddAssignment = async () => {
    if (assignmentName.trim() === '') {
      toast.error('Assignment name cannot be empty');
      return;
    }

    if (selectedResources.length === 0) {
      alert('Please select at least one resource for the assignment.');
      return;
    }

    setIsLoading(true);

    const newAssignment: Assignment = {
      name: assignmentName,
      description: assignmentInstruction,
      resources: selectedResources,
    };

    const requestBody = {
      topicId,
      name: newAssignment.name,
      description: newAssignment.description,
      resources: newAssignment.resources.map((resource: any) => resource.resource_id),
    };

    try {
      const response = await addAssignment(requestBody);
      console.log('Assignment added successfully:', response);
      toast.success('Assignment added successfully');

      setSubmittedAssignments((prevAssignments) => [...prevAssignments, newAssignment]);
      setAssignmentName('');
      setAssignmentInstruction('');
      setSelectedResources([]);

      onSave(newAssignment);
      toggleModal(); // Close modal on successful save
    } catch (error) {
      console.error('Error adding assignment:', error);
      alert('There was an error adding the assignment. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (content: string, delta: any, source: string, editor: any) => {
    if (source === 'user') {
      // Check if the delta contains a paste operation
      const pastedContent = delta.ops?.some((op: any) => op.insert && typeof op.insert === 'string');
      if (pastedContent) {
        // Apply Heading 3 formatting to the entire pasted content
        setTimeout(() => {
          const quill = editor.getEditor();
          quill.format('header', 3);
        }, 0); // Defer formatting to ensure paste operation is completed
      }
    }
    setEditorContent(content);
  };

  const handleResourceSelection = (resource: Resource) => {
    if (selectedResources.includes(resource)) {
      setSelectedResources(selectedResources.filter((res) => res !== resource));
    } else {
      setSelectedResources([...selectedResources, resource]);
    }
  };

  return (
    <div style={{ marginTop: '10px' }}>
      {/* Existing Assignments List */}
      <div style={{ maxHeight: '80vh', overflowY: 'auto' }}>
        <ListGroup>
          {topic.assignments.map((assignment: any, index: number) => (
            <ListGroupItem key={index}>
              <Card>
                <CardBody style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px' }}>
                  <div>
                    <strong>Name: </strong> {assignment.name}
                    <br />
                    <strong>Description: </strong>  
                    <span style={{ marginRight: '10px' }} dangerouslySetInnerHTML={{ __html: assignment.description }} />
                    <h6>
                      <strong>Resources: </strong>
                      {assignment.resources.map((resource: any, i: number) => (
                        <span key={i} style={{ marginRight: '10px' }}>{resource.resource_name}</span>
                      ))}
                    </h6>
                  </div>
                  <Button onClick={() => openDeleteModal(topicId, assignment.assignment_id)}>
                    <Trash size={18} />
                  </Button>
                </CardBody>
              </Card>
            </ListGroupItem>
          ))}
        </ListGroup>
      </div>

      {/* Button to open the modal */}
      <Button color="primary" onClick={toggleModal} style={{ marginTop: '10px' }}>
        Add Assignment 
      </Button>

      {/* Modal for adding new assignment */}
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add Assignment</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="assignmentName">Assignment Name</Label>
            <span style={{ color: 'red' }}>*</span>
            <Input
              type="text"
              id="assignmentName"
              value={assignmentName}
              onChange={(e) => setAssignmentName(e.target.value)}
              placeholder="Enter assignment name"
            />
          </FormGroup>

          <FormGroup>
            <Label for="assignmentInstruction">Assignment Instruction</Label>
            <ReactQuill
            
            modules={{
              toolbar: [
                [{ header: [1, 2, 3, false] }], // Add toolbar options
                ['bold', 'italic', 'underline'],
                ['link', 'image'],
              ],
            }}
              id="assignmentInstruction"
              value={assignmentInstruction}
              onChange={(value) => setAssignmentInstruction(value)}
              style={{ marginBottom: '10px' }}
            />
          </FormGroup>

          <h6>Existing Resources</h6>
          <ListGroup>
            {existingResources.map((resource, index) => (
              <ListGroupItem key={index} className="d-flex justify-content-between align-items-center">
                <div>
                  <Input
                    type="checkbox"
                    checked={selectedResources.includes(resource)}
                    className="levelChk"
                    onChange={() => handleResourceSelection(resource)}
                    style={{ accentColor: 'blue' }}
                  />
                  <span style={{ marginLeft: '10px' }}>{(resource as any).resource_name}</span>
                </div>
              </ListGroupItem>
            ))}
          </ListGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleAddAssignment} disabled={isLoading}>
            {isLoading ? 'Adding...' : 'Save Assignment'}
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddAssignmentCard;
