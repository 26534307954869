import React, { useState, useRef } from 'react';
import { Card, CardBody, CardHeader, Button, Input, FormGroup, Label, Row, Col, Collapse , Modal, ModalBody, ModalHeader, ModalFooter, Spinner } from 'reactstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import classnames from 'classnames';
import { signedURL, uploadResourse, addResource, addAssignment,removeResource } from '../../../../Api/lms/apiUtils'; 
import Search from './search';
import { H5, H4 } from '../../../../AbstractElements';
import { toast } from 'react-toastify';
import { Trash } from 'react-feather';
import './style.scss';
import { Video, Music, FileText , } from 'react-feather';
import { MdOutlineCancel } from "react-icons/md";


interface Resource {
  type: any;
  name: any;
  file: File;
}

interface Assignment {
  name: any;
  instructions: any;
  resources: Resource[];
}

interface AddTopicCardProps {
  savedTopic: any;
  onSaveTopic: (topicName: any, details: any, resources: Resource[], isExtended: boolean, artiumGrade: boolean, trinityGrade: boolean) => void;
  onSaveAssignment: (assignments: Assignment[]) => void;
  toggle: () => void;
}

const AddTopicCard: React.FC<AddTopicCardProps> = ({
  savedTopic,
  onSaveTopic,
  onSaveAssignment,
  toggle
}) => {
  const [topicName, setTopicName] = useState<any>('');
  const [details, setDetails] = useState<any>('');
  const [extendToFutureLessons, setExtendToFutureLessons] = useState<boolean>(false);
  const [resources, setResources] = useState<Resource[]>([]);
  const [resourceType, setResourceType] = useState<any>();
  const [resourceFiles, setResourceFiles] = useState<FileList | null>(null);
  const [displayName, setDisplayName] = useState<any>('');
  const [editorContent, setEditorContent] = useState<any>('');
  const [validationMessage, setValidationMessage] = useState<string>('');
  const [assignments, setAssignments] = useState<Assignment[]>([]);
  const [showAssignmentSection, setShowAssignmentSection] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
const [resourceToDelete, setResourceToDelete] = useState<Resource | null>(null);
  const [newAssignmentName, setNewAssignmentName] = useState<any>('');
  const [newAssignmentInstruction, setNewAssignmentInstruction] = useState<any>('');
  const [assignmentResources, setAssignmentResources] = useState<Resource[]>([]);
  const [isTopicSaved, setIsTopicSaved] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false); // State to handle collapse
  const [isFileModalOpen, setFileModalOpen] = useState(false);
  const [isResourceSaved, setResourceSaved] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileDisplayName, setFileDisplayName] = useState('');
  const [isUploading, setIsUploading] = useState(false); 


  // Additional states for checkboxes
  const [isExtended, setIsExtended] = useState<boolean>(false); 
  const [artiumGrade, setArtiumGrade] = useState<boolean>(false);
  const [trinityGrade, setTrinityGrade] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpenExisting, setIsOpenExsiting] = useState(false);

  const toggleFileModal = () => setFileModalOpen(!isFileModalOpen);
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const toggleExistingCollapse = () => setIsOpen(!isOpen);

  const toggleCollapse = () => setIsOpen(!isOpen); // Toggle function for collapse
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // This resets the input field.
    }
  };
  
 const handleAddResources = async () => {
  setIsUploading(true);
  if (resourceFiles) {
    const newResources = Array.from(resourceFiles).map((file) => ({
      type: resourceFiles[0].name.split('.').pop(),
      name: (displayName || file.name).split(' ').join('_'), 
      file: file,
    }));

    try {
      const fileExtension = resourceFiles[0].name.split('.').pop();
      const response = await signedURL({ fileName: newResources[0].name, fileType: fileExtension });
      if (response.status === 200) {
        (newResources[0] as any).resource_name = response.data.fileName; // Update the resource name
        const { signedUrl } = response.data;
        const uploadResponse = await uploadResourse(signedUrl, newResources[0].file);
        if (uploadResponse.status === 200) {
          const resourceResponse = await addResource({
            resourceName: (newResources[0] as any).resource_name,
            displayName: displayName || newResources[0].name,
            type: fileExtension,
            description: " ",
            url: " ", 
          });

          if (resourceResponse.status === 200) {
            (newResources[0] as any).resource_id = resourceResponse.data.resource[0].resource_id;
            setResources([...resources, ...newResources]);
            toast.success('File uploaded successfully', resourceResponse);
            
            // Clear input and reset state
            clearFileInput();
            setResourceFiles(null);
            setDisplayName('');
          } else {
            toast.error('Error adding resource', resourceResponse);
          }
        } else {
          console.error('Error uploading file', uploadResponse);
        }
      }
    } catch (error) {
      console.error('Error during file upload process', error);
    } finally {
      setIsUploading(false);
    }
  }
};


  const openDeleteModal = (resource: Resource) => {
    setResourceToDelete(resource);
    setIsDeleteModalOpen(true);
  };
  
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setResourceToDelete(null);
  };


  const deleteResource = async () => {
    if (resourceToDelete && savedTopic) {
      try {
        const response = await removeResource((resourceToDelete as any).resource_id);
        if (response.status === 200) {
          toast.success('Resource removed successfully');
          const updatedResources = resources.filter(
            (res: Resource) => res !== resourceToDelete
          );
          setResources(updatedResources); // Update the state to reflect the removed resource
          closeDeleteModal(); // Close the modal after successful deletion
        } else {
          toast.error('Failed to remove resource:', response.data.message);
        }
      } catch (error) {
        console.error('Error removing resource:', error);
        toast.error('Unable to delete the resource. Save the topic first, then try deleting the resource');
      }
    }
  };
  // Save the topic and resources
  const handleSaveTopic = () => {
    if (topicName) {
      onSaveTopic(topicName, editorContent, resources, isExtended, artiumGrade, trinityGrade);
  
    } else {
      toast.error('Topic name is required');
    }
  };

  // Add a new assignment
  const handleAddAssignment = async() => {
    if (newAssignmentName && newAssignmentInstruction) {
      const newAssignment: Assignment = {
        name: newAssignmentName,
        instructions: newAssignmentInstruction,
        resources: assignmentResources,
      };
      if (savedTopic) {
        let reqParam = {
          "topicId": (savedTopic as any)?.topic_id,
          "name": newAssignment.name,
          "description": newAssignment.instructions,
          "resources": newAssignment.resources.map((resource: any) => ( resource.resource_id))
        };
  
        // const updatedAssignments = [...(selectedTopic.resources || []), ...assignments.resources];
        // setSelectedTopic({
        //   ...selectedTopic,
        //   resources: updatedAssignments,
        // });
  
        try {
          const response = await addAssignment(reqParam);
          if (response.status === 201) {
            setAssignments([...assignments, newAssignment]);
            setNewAssignmentName('');
            setNewAssignmentInstruction('');
            setAssignmentResources([]);
           toast.success('Assignment added successfully');
           
          } else {
            console.error('Failed to add assignment:', response.data.message);
          }
        } catch (error) {
          console.error('Error adding assignment:', error);
        }
      }
    } else {
      alert('Assignment name and instructions are required');
    }
  };

  // Save assignments after topic is saved
  const handleSaveAssignments = () => {
    if (assignments.length > 0) {
      onSaveAssignment(assignments);
    } else {
      alert('Please add at least one assignment.');
    }
  };

  // Handle assignment resource selection
  const handleAssignmentResourceSelection = (resource: Resource, isChecked: boolean) => {
    if (isChecked) {
      setAssignmentResources([...assignmentResources, resource]);
    } else {
      setAssignmentResources(assignmentResources.filter((r) => r.name !== resource.name));
    }
  };

  return (
    <Card>
   <CardHeader>
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    <h2 style={{ color: "#e82c86" }}>Add Topic</h2>
    <Button style={{ color: "#e82c86" }} onClick={toggle}>
      <MdOutlineCancel size={22} />
    </Button>
  </div>
</CardHeader>

      <CardBody>
        {/* Topic Name */}
        <FormGroup>
  <Label for="topicName">
 <b>   Topic Name: </b><span style={{ color: 'red' }}>*</span>
  </Label>
  <Input
    type="text"
    id="topicName"
    required
    value={topicName}
    onChange={(e) => setTopicName(e.target.value)}
    disabled={isTopicSaved}
  />
</FormGroup>


        {/* Topic Details */}
        <FormGroup>
          <Label for="details"><b>Details:</b> </Label>
          <ReactQuill value={editorContent} onChange={setEditorContent} />
        </FormGroup>

        {/* Additional Checkboxes */}
        <FormGroup>
  <Row form>
    <Col md={4}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Label style={{ marginRight: '10px' }}><b>Is this an extended:</b></Label>
        <Input
          type="radio"
          name="isExtended"
          className="levelChk"
          value="yes"
          checked={isExtended === true}
          onChange={() => setIsExtended(true)}
        />
        <Label style={{ marginLeft: '5px', marginRight: '15px' }}>Yes</Label>
        <Input
          type="radio"
          name="isExtended"
          value="no"
          className="levelChk"
          checked={isExtended === false}
          onChange={() => setIsExtended(false)}
        />
        <Label style={{ marginLeft: '5px' }}>No</Label>
      </div>
    </Col>
  </Row>
</FormGroup>

{resources.length > 0 && (
  <h4 style={{ marginTop: '10px' }}>Resource List</h4>
)}

<ul style={{ marginTop: '10px' }}>
  {resources.map((resource, index) => (
    <li key={index}>
      <Card>
        <CardBody>
          <div style={{ display: "flex", justifyContent: "space-between" }}> 
            <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
              {resource.type === 'video' && <Video color="grey" />}
              {resource.type === 'audio' && <Music color="grey" />}
              {resource.type === 'article' && <FileText color="grey" />}
              {resource.type === 'pdf' && <FileText color="grey" />}
              {resource.type === 'mp4' && <Video color="grey" />}
              {resource.type === 'mp3' && <Music color="grey" />}

              <span style={{ marginLeft: "10px" }}>
                {resource.name || (resource as any).display_name || (resource as any).resource_name}
              </span>
            </div>
            <Trash onClick={() => openDeleteModal(resource)} size={18} />
          </div>       
        </CardBody>
      </Card>
    </li>
  ))}
</ul>


<Card>




<div >


<div >
      {/* Button to open modal */}
 

      {/* Modal structure */}
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add Existing Resources</ModalHeader>
        <ModalBody>
          {/* Card component inside the modal */}
   
             


       
              <Search setResources={setResources} />

  
        </ModalBody>
      </Modal>
 


    
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
  <FormGroup style={{ flex: 1, marginRight: '10px' }}>
    <Label for="fileUpload"><b>Choose File</b></Label>
    <input
      type="file"
      ref={fileInputRef}
      onChange={(e) => {
        if (e.target.files && e.target.files.length > 0) {
          setResourceFiles(e.target.files);
          const firstFileName = e.target.files[0].name.split('.')[0]; // Extract file name without extension
          setDisplayName(firstFileName); 
        }
      }}
      accept="video/*,audio/*,application/pdf"
      className="form-control"
    />
  </FormGroup>

  <FormGroup style={{ flex: 1, marginRight: '10px' }}>
    <Label for="displayName"><b>Display Name</b></Label>
    <Input
      type="text"
      placeholder="Display Name (optional)"
      value={displayName}
      onChange={(e) => setDisplayName(e.target.value)}
    />
  </FormGroup>

  {!isTopicSaved && (
    <Button 
      color="primary" 
      onClick={() => {
        // Only trigger upload if files are selected
        if (resourceFiles && resourceFiles.length > 0) {
          handleAddResources();
        } else {
          // Optionally show a message or prevent the action
        toast.error("Please select files to upload.");
        }
      }} 
      style={{ marginTop: '10px' }} 
      disabled={isUploading} // Disable button while uploading
    >
      {isUploading ? (
        <Spinner size="sm" /> // Show spinner while uploading
      ) : (
        'Add Resources'
      )}
    </Button>
  )}
</div>

              <div style={{display:"flex", justifyContent:"center",}} >    <H5  >Or</H5></div>
              <div style={{display:"flex", justifyContent:"center", marginTop:"10px"}} >
              <Button 
    color="secondary"
    className={classnames('custom-cancel-button')} onClick={toggleModal}>
        Add Existing Resource
      </Button>
      </div>
</div>
    </div></Card>


       


   
      {/* Delete confirmation modal */}
      <Modal isOpen={isDeleteModalOpen} toggle={closeDeleteModal}>
        <ModalHeader toggle={closeDeleteModal}>Confirm Delete</ModalHeader>
        <ModalBody>Are you sure you want to delete this resource?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={deleteResource}>
            Delete
          </Button>
          <Button className="custom-cancel-button" onClick={closeDeleteModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
       
          {!isTopicSaved && (
            <Button color="primary" onClick={handleSaveTopic}>
              Save Topic
            </Button>
          )}
      
    

        {isTopicSaved && (
          <>
            {/* Assignment Section */}
            <FormGroup style={{ marginTop: '20px' }}>
              <Label>Assignments:</Label>
              <Button
                color="info"
                onClick={() => setShowAssignmentSection(!showAssignmentSection)}
                style={{ marginLeft: '10px' }}
              >
                {showAssignmentSection ? 'Hide' : 'Add Assignment'}
              </Button>
            </FormGroup>

            {showAssignmentSection && (
              <>
                <FormGroup>
                  <Label for="assignmentName">Assignment Name:</Label>
                  <Input
                    type="text"
                    id="assignmentName"
                    value={newAssignmentName}
                    onChange={(e) => setNewAssignmentName(e.target.value)}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="assignmentInstructions">Instructions:</Label>
                  <ReactQuill value={newAssignmentInstruction} onChange={setNewAssignmentInstruction} />
                </FormGroup>

                {/* Assignment Resources */}
                <FormGroup>
                  <Label>Assignment Resources:</Label>
                  {resources.map((resource, index) => (
                    <div key={index}>
                      <Input
                        type="checkbox"
                        className='levelChk'
                        onChange={(e) => handleAssignmentResourceSelection(resource, e.target.checked)}
                      />
                      <Label>{resource.name || (resource as any).display_name ||(resource as any).resource_name }</Label>
                    </div>
                  ))}
                </FormGroup>

                <Button color="primary" onClick={handleAddAssignment}>
                  Add Assignment +
                </Button>
              </>
            )}

            <ul>
              {assignments.map((assignment, index) => (
                <li key={index}>
                  {assignment.name} - {assignment.instructions}
                  <ul>
                    {assignment.resources.map((resource, resIndex) => (
                      <li key={resIndex}>
                        {resource.type.toUpperCase()}: {resource.name || (resource as any).display_name ||(resource as any).resource_name }
                      </li>
                    ))}
                  </ul>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                  {/* <Button color="success" onClick={handleSaveAssignments} >
                    Save Assignments
                  </Button> */}
                  <Button color="primary" onClick={toggle} style={{ marginTop: '20px' }}>
                    Cancel
                  </Button>
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default AddTopicCard;
