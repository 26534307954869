import axios from 'axios';
import { WEB_API_BASEURL, API_URLS } from '../api-constant';
import { write } from 'fs';

// Create
export const createCourse = async (data: any) => {
    try {
        const response = await axios.post(API_URLS.addCourseWithLevels, data);
        return response.data;
    } catch (error) {
        console.error('Error creating item:', error);
        throw error;
    }
};

export const addLevel = async (data: any) => {
    try {
        const response = await axios.post(API_URLS.level, data);
        return response;
    } catch (error) {
        console.error('Error creating item:', error);
        throw error;
    }
};

export const getTags = async (data: any) => {
    try {
        const response = await axios.post(API_URLS.getTags, data);
        return response;
    } catch (error) {
        console.error('Error creating item:', error);
        throw error;
    }
};

export const addLesson = async (data: any) => {
    try {
        const response = await axios.post(API_URLS.lesson, data);
        return response;
    } catch (error) {
        console.error('Error creating item:', error);
        throw error;
    }
};

export const getCourses = async () => {
    try {
        const response = await axios.get(API_URLS.course);
        return response.data;
    } catch (error) {
        console.error('Error fetching items:', error);
        throw error;
    }
};

export const getLessons = async (levelId:any) => {
    try {
        let url = API_URLS.lessonWithLevelId;
        url = url.replace('{levelId}', levelId);
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('Error fetching items:', error);
        throw error;
    }
};

export const getTopics = async (lessonId: any) => {
    try {
        let url = API_URLS.topicsWithLessonId;
        url = url.replace('{lessonId}', lessonId);
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('Error fetching items:', error);
        throw error;
    }
};

export const getTopicWithResource = async (topicId: any) => {
    try {
        let url = API_URLS.topicWithTopicId;
        url = url.replace('{topicId}', topicId);
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('Error fetching items:', error);
        throw error;
    }
};

export const addTopic = async (data: any) => {
    try {
        const response = await axios.post(API_URLS.topicWithResource, data);
        return response;
    } catch (error) {
        console.error('Error creating item:', error);
        throw error;
    }
};

export const signedURL = async (data: any) => {
    try {
        const response = await axios.post(API_URLS.signedURL, data);
        return response;
    } catch (error) {
        console.error('Error creating item:', error);
        throw error;
    }
};



export const addResource = async (data: any, config = {}) => {
  try {
    const response = await axios.post(API_URLS.resource, data, config); // Pass config here
    return response;
  } catch (error) {
    console.error('Error creating item:', error);
    throw error;
  }
};


export const addExistingResourceToTopic = async (data: any) => {
    try {
        const response = await axios.post(API_URLS.addExistingResourceToTopic, data);
        return response;
    } catch (error) {
        console.error('Error adding existing resource to topic:', error);
        throw error;
    }
};
export const uploadResourse = async (url:any, data:any) => {
    try {
        const response = await axios.put(url, data, ({ skipAuth: true } as any));
        return response;
    } catch (error) {
        console.error('Error creating item:', error);
        throw error;
    }
};

export const addSong = async (data: any) => {
    try {
        const response = await axios.post(API_URLS.song, data);
        return response;
    } catch (error) {
        console.error('Error creating item:', error);
        throw error;
    }
};

export const addSongWithLevel = async (data: any) => {
    try {
        const response = await axios.post(API_URLS.songWithLevel, data);
        return response;
    } catch (error) {
        console.error('Error creating item:', error);
        throw error;
    }
};


export const addAssignment = async (data: any) => {
    try {
        const response = await axios.post(API_URLS.assignment, data);
        return response;
    } catch (error) {
        console.error('Error creating item:', error);
        throw error;
    }
};

export const removeTopicResource = async (topicId: any, resourceId: any) => {
    try {
        let url = API_URLS.removeTopicResource;
        url = url.replace('{topicId}', topicId).replace('{resourceId}', resourceId);
        const response = await axios.delete(url);
        return response;
    } catch (error) {
        console.error('Error removing topic resource:', error);
        throw error;
    }
};

export const deleteAssignmentFromTopic = async (topicId: any, assignmentId: any) => {
    try {
        let url = API_URLS.deleteAssignmentFromTopic;
        url = url.replace('{topicId}', topicId).replace('{assignmentId}', assignmentId);
        const response = await axios.delete(url);
        return response;
    } catch (error) {
        console.error('Error deleting assignment from topic:', error);
        throw error;
    }
};

export const removeSongFromLevel = async ( songId :any,levelId: any) => {
    try {
        let url = API_URLS.removeSongFromLevel;
        url = url.replace('{songId}', songId).replace('{levelId}', levelId);
        const response = await axios.delete(url);
        return response;
    } catch (error) {
        console.error('Error removing song from level:', error);
        throw error;
    }
};

export const updateLesson = async (id: any, data: any) => {
    try {
        let url = API_URLS.lessonWithLessonId;
        url = url.replace('{lessonId}', id);
        const response = await axios.patch(url, data);
        return response;
    } catch (error) {
        console.error('Error updating lesson:', error);
        throw error;
    }
};

// Update
export const updateItem = async (id: string, data: any) => {
    try {
        const response = await axios.put(`${API_URLS.course}/${id}`, data);
        return response.data;
    } catch (error) {
        console.error('Error updating item:', error);
        throw error;
    }
};

// Delete
export const deleteItem = async (id: string) => {
    try {
        const response = await axios.delete(`${API_URLS.course}/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting item:', error);
        throw error;
    }
};
export const searchResources = async (displayName: any, pageNumber: number, pageSize: number) => {
    try {
        let url = API_URLS.searchURL;
        url = url
            .replace('{displayName}', displayName)
            .replace('{pageNumber}', pageNumber.toString())
            .replace('{pageSize}', pageSize.toString());

        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('Error fetching search results:', error);
        throw error;
    }
};

export const getSongURL = async (songName: any, pageNumber: any, pageSize: any) => {
    try {
        let url = API_URLS.getSongURL;
        url = url
            .replace('{SongName}', songName)
            .replace('{pageNumber}', pageNumber.toString())
            .replace('{pageSize}', pageSize.toString());

        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('Error fetching songs:', error);
        throw error;
    }
};
export const getSongLevel = async (levelId: any) => {
    try {
        let url = API_URLS.getSongLevel;
        url = url.replace('{levelId}', levelId);
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('Error fetching songs for level:', error);
        throw error;
    }
};
export const editTopic = async (topicId: any, data: any) => {
    try {
        let url = API_URLS.topicWithTopicId;
        url = url.replace('{topicId}', topicId);
        const response = await axios.patch(url, data);
        return response;
    } catch (error) {
        console.error('Error editing topic:', error);
        throw error;
    }
};


// export const removeSongFromLevel = async (songId: any, levelId:any) => {
//     try {
//         let url = API_URLS.removeSong;
//         url = url
//             .replace('{songId}', songId.toString())
//             .replace('{levelId}', levelId.toString());

//         const response = await axios.delete(url);
//         return response;
//     } catch (error) {
//         console.error('Error removing song from level:', error);
//         throw error;
//     }
// };

// export const removeTopicResource = async (topicId: any, resourceId: any) => {
//     try {
//         let url = API_URLS.removeTopicResources;
//         url = url
//             .replace('{topicId}', topicId.toString())
//             .replace('{resourceId}', resourceId.toString());

//         const response = await axios.delete(url);
//         return response;
//     } catch (error) {
//         console.error('Error removing topic resource:', error);
//         throw error;
//     }
// };

export const removeResource = async (resourceId: any) => {
    try {
        let url = API_URLS.removeResource;
        url = url.replace('{resourceId}', resourceId);
        const response = await axios.delete(url);
        return response;
    } catch (error) {
        console.error('Error removing topic resource:', error);
        throw error;
    }
};

export const editSong = async (songId: any) => {
    try {
        let url = API_URLS.editSong;
        url = url.replace('{songId}', songId);
        const response = await axios.patch(url);
        return response;
    } catch (error) {
        console.error('Song updated successfully:', error);
        throw error;
    }
};