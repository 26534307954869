import { addExistingResourceToTopic, signedURL, updateLesson } from "./lms/apiUtils";

export const CONFIG_BASE_DOMAIN = process.env.REACT_APP_CONFIG_BASE_DOMAIN;
export const WEB_API_BASEURL = process.env.REACT_APP_WEBAPI_BASEURL;
export const UTILITY_BASEURL = process.env.REACT_APP_UTILITY_BASEURL;
export const LMS_BASEURL = process.env.REACT_APP_LMS_BASEURL;

export const API_URLS = {
    course: `${LMS_BASEURL}/api/lms/course`,
    level: `${LMS_BASEURL}/api/lms/level`,
    lesson: `${LMS_BASEURL}/api/lms/lesson`,
    resource: `${LMS_BASEURL}/api/lms/resource`,
    song: `${LMS_BASEURL}/api/lms/songs`,
    songWithLevel: `${LMS_BASEURL}/api/lms/level/song`,
    removeTopicResource: `${LMS_BASEURL}/api/lms/topic-resource/{topicId}?resourceId={resourceId}`,
    addExistingResourceToTopic: `${LMS_BASEURL}/api/lms/topic/existing-resource`,
    deleteAssignmentFromTopic: `${LMS_BASEURL}/api/lms/topic-assignment?topicId={topicId}&assignmentId={assignmentId}`,
    removeSongFromLevel: `${LMS_BASEURL}/api/lms/level/song/{songId}?levelId={levelId}`,
    assignment: `${LMS_BASEURL}/api/lms/topic/assignment`,
    lessonWithLessonId: `${LMS_BASEURL}/api/lms/lesson/{lessonId}`,
    lessonWithLevelId: `${LMS_BASEURL}/api/lms/level/lesson/{levelId}`,
    removeTopicResources:`${LMS_BASEURL}/api/lms/topic-resource/{topicId}?resourceId={resourceId}`,
    topicWithResource: `${LMS_BASEURL}/api/lms/topic-resource`,
    topicsWithLessonId: `${LMS_BASEURL}/api/lms/lesson/topic/{lessonId}`,
    topicWithTopicId: `${LMS_BASEURL}/api/lms/topic/{topicId}`,
    getAllLevelOfCourse: `${LMS_BASEURL}/api/lms/course/level/{courseId}`,
    addCourseWithLevels: `${LMS_BASEURL}/api/lms/course-with-levels`,
    signedURL: `${LMS_BASEURL}/api/resource/presigned-url`,
    searchURL:`${LMS_BASEURL}/api/lms/search-resources/?displayName={displayName}&pageNumber={pageNumber}&pageSize={pageSize}`,
    getSongURL:`${LMS_BASEURL}/api/lms/search-songs/?songName={SongName}&pageNumber={pageNumber}&pageSize={pageSize}`,
    getSongLevel:`${LMS_BASEURL}/api/lms/level/all-songs/{levelId}`,
    removeSong:`${LMS_BASEURL}/api/lms/level/song/{songId}?levelId={levelId}`,
    removeResource:`${LMS_BASEURL}/api/lms/resource/{resourceId}`,
    editSong:`${LMS_BASEURL}/api/lms/song/{songId}`,

    // addAssignmnet:`${LMS_BASEURL}/api/lms/topic/assignment/`,
    getTags:`${LMS_BASEURL}/api/lms/song-tags`,
    // http://localhost:3000/api/lms/search-resources/?displayName=topic_1&pageNumber=1&pageSize=21

    subjects: `${WEB_API_BASEURL}/admin/subjects`,
    chapters: `${WEB_API_BASEURL}/admin/chapters`,
    topics: `${WEB_API_BASEURL}/admin/topics`,
    lessons: `${WEB_API_BASEURL}/admin/lessons`,
    quizzes: `${WEB_API_BASEURL}/admin/quizzes`,
    questions: `${WEB_API_BASEURL}/admin/questions`,
    options: `${WEB_API_BASEURL}/admin/options`,
    answers: `${WEB_API_BASEURL}/admin/answers`,
    credentials: `${WEB_API_BASEURL}/admin/credentials`,
    login: `${WEB_API_BASEURL}/auth/login`,
    logout: `${WEB_API_BASEURL}/auth/logout`,
    register: `${WEB_API_BASEURL}/auth/register`,
    forgotPassword: `${WEB_API_BASEURL}/auth/forgot-password`,
    resetPassword: `${WEB_API_BASEURL}/auth/reset-password`,
    changePassword: `${WEB_API_BASEURL}/auth/change-password`,
    profile: `${WEB_API_BASEURL}/auth/profile`,
    upload: `${UTILITY_BASEURL}/upload`,
    download: `${UTILITY_BASEURL}/download`,
    delete: `${UTILITY_BASEURL}/delete`,
    config: `${CONFIG_BASE_DOMAIN}/config/uat/config.json`
}