import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button } from 'reactstrap';
import { searchResources } from '../../../../Api/lms/apiUtils'; // Import your API utility
import PreviewComponent from './PreviewComponent';
import { Eye } from 'react-feather';
import { toast } from 'react-toastify';

interface Resource {
  resource_id: number;
  resource_name: any;
  type: any;
  description: any;
  url: any;
  fileUrl: string;
  display_name: any;
}

const SearchComponent: React.FC<any> = ({ setResources, selectedTopic, handleAddExistingResourceToTopic }) => {
  const [query, setQuery] = useState<string>('');
  const [allResources, setAllResources] = useState<Resource[]>([]);
  const [filteredResources, setFilteredResources] = useState<Resource[]>([]);
  const [displayedResources, setDisplayedResources] = useState<Resource[]>([]);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [selectedResources, setSelectedResources] = useState<Set<number>>(new Set());
  const [addedResources, setAddedResources] = useState<Resource[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [previewResource, setPreviewResource] = useState<Resource | null>(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const observerRef = useRef<IntersectionObserver | null>(null);

  const lastResourceRef = useCallback((node: HTMLDivElement) => {
    if (isLoading) return;

    if (observerRef.current) observerRef.current.disconnect();

    observerRef.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });

    if (node) observerRef.current.observe(node);
  }, [isLoading, hasMore]);

  const fetchResources = async (searchQuery: string, pageNumber: number) => {
    setIsLoading(true);
    try {
      const response = await searchResources(searchQuery, pageNumber, 10);
      const newResources = response.results || [];

      if (newResources.length === 0) {
        setHasMore(false);
      } else {
        setAllResources(prevResources => [...prevResources, ...newResources]);
        setFilteredResources(prevResources => [...prevResources, ...newResources]);
        setDisplayedResources(prevResources => [...prevResources, ...newResources]);
      }
    } catch (error) {
      console.error('Error fetching resources:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setAllResources([]);
    setFilteredResources([]);
    setDisplayedResources([]);
    setPage(1);
    setHasMore(true);
    fetchResources(query, 1);
  }, [query]);

  useEffect(() => {
    if (page > 1) {
      fetchResources(query, page);
    }
  }, [page]);

  const handlePreviewToggle = (resource: Resource | null) => {
    setPreviewResource(resource);
    setIsPreviewOpen(!isPreviewOpen);
  };

  const toggleResourceSelection = (resource_id: number) => {
    setSelectedResources(prevSelected => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(resource_id)) {
        newSelected.delete(resource_id);
      } else {
        newSelected.add(resource_id);
      }
      return newSelected;
    });
  };

  const addSelectedResources = () => {
    const selected = filteredResources.filter(resource => selectedResources.has(resource.resource_id));
    if (selectedTopic) {
      handleAddExistingResourceToTopic(selected);
      setSelectedResources(new Set());
      toast.success('File uploaded successfully');
      
    } else {
      setAddedResources(prev => [...prev, ...selected]);
      setResources((prev: any) => [...prev, ...selected]);
      setSelectedResources(new Set());
      toast.success('File Added successfully');
    }
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Search resources..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        style={{ width: '100%', padding: '10px', marginBottom: '10px' }}
      />

      {isLoading && <p>Loading resources...</p>}

      <div style={{ height: '200px', overflowY: 'auto', border: '1px solid #ccc', marginBottom: '10px' }}>
        {displayedResources.map((resource, index) => {
          const isSelected = selectedResources.has(resource.resource_id);
          const resourceDisplayName = resource.display_name || resource.resource_name;

          if (index === displayedResources.length - 1) {
            return (
              <div
                ref={lastResourceRef}
                key={resource.resource_id}
                style={{ padding: '10px', borderBottom: '1px solid #ddd' }}
              >
                <input
                  type="checkbox"
                  checked={isSelected}
                  onChange={() => toggleResourceSelection(resource.resource_id)}
                  style={{ marginRight: '10px' }}
                />
                <div onClick={() => handlePreviewToggle(resource)}  style={{ display: 'flex', alignItems: 'center' }}>
                  <h3 style={{ marginRight: '10px' }}>{resourceDisplayName}</h3>
                  {/* <Eye style={{ cursor: 'pointer' }} /> */}
                </div>
                <p>{resource.description}</p>
              </div>
            );
          } else {
            return (
              <div key={resource.resource_id} style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                <input
                  type="checkbox"
                  checked={isSelected}
                  onChange={() => toggleResourceSelection(resource.resource_id)}
                  style={{ marginRight: '10px' }}
                />
                <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => handlePreviewToggle(resource)}>
                  <h3 style={{ marginRight: '10px' }}>{resourceDisplayName}</h3>
              
                </div>
                <p>{resource.description}</p>
              </div>
            );
          }
        })}
        {previewResource && (
          <PreviewComponent
            resource={previewResource}
            isOpen={isPreviewOpen}
            onClose={() => handlePreviewToggle(null)}
          />
        )}
        {!query && <p>Start typing to search for resources...</p>}
      </div>

      <Button color="primary" onClick={addSelectedResources} style={{ marginBottom: '10px' }}>
        {selectedTopic ? 'Save' : 'Add'} Selected Resources
      </Button>
    </div>
  );
};

export default SearchComponent;
